import { NetworkStatus, useMutation } from "@apollo/client";
import GridLoader from "@components/Loaders/GridLoader";
import useNotifier from "hooks/useNotifier";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateItemInput } from "apps/things/app/types/thingsGlobalTypes";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import AddItemSKU from "../components/AddItem";
import { defaultQueryVariables, itemsRoutes } from "../constants";
import { ItemContext } from "../context/ItemProvider";
import { createItemMutation } from "../mutations";
import { useGetAllItemsQuery } from "../queries";
import { CreateItem, CreateItemVariables } from "../types/CreateItem";
import { countTrailingXs } from "../../utils";
const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();

  const [totalItems, setTotalItems] = useState(0);

  const { setItems } = React.useContext(ItemContext);
  const { refetch, data, networkStatus } = useGetAllItemsQuery(
    defaultQueryVariables
  );

  React.useEffect(() => {
    let lastMax = 0;

    data?.getAllItems?.page?.edges?.map((item) => {
      const currentNum: number =
        parseInt(item.node?.oemItemID?.slice(-4) || "") || 0;
      lastMax = Math.max(lastMax, currentNum);
      return item;
    });

    setItems(data?.getAllItems?.page?.edges || []);
    // eslint-disable-next-line
  }, [data]);

  const [createItem, createItemOpts] = useMutation<
    CreateItem,
    CreateItemVariables
  >(createItemMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      setTotalItems(totalItems + 1);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const [_loading, setLoading] = useState(false);

  const handleSubmit = async (
    createItemInput: CreateItemInput,
    totalItems: number
  ) => {
    let numberOfXs = countTrailingXs(createItemInput.oemItemID);
    let totalNumbersAllowed = (Math.pow(10, numberOfXs) - 1)
    if (totalNumbersAllowed< totalItems) {
      notify({
      status: "error",
      text: `Total Number of Items you can create are ${totalNumbersAllowed}`,
    });
    } 
    else {
    setLoading(true);

    await createItem({
      variables: {
        createItemInput: {
          ...createItemInput,
          itemfirmwareId: createItemInput.itemfirmwareId || "",
          oemItemID: createItemInput.oemItemID,
          totalProducts: totalItems || 1,
        },
      },
    });

    notify({
      status: "success",
      text: `${totalItems} items created successfully.`,
    });
    await refetch();
    setLoading(false);
    history(itemsRoutes.list);
    }
  };

  if (networkStatus === NetworkStatus.refetch) return <GridLoader />;

  return (
    <AddItemSKU
      handleSubmit={handleSubmit}
      isLoading={createItemOpts.loading || _loading}
    />
  );
};

export default AddItemContainer;
