export const itemBatchFields = {
  batchNumber: 'batchNumber',
  batchDate: 'batchDate',
  description: 'description',
  itemSKUId: 'itemSKUId',
};

export const itemBatchRoutes = {
  list: '/admin/batch',
  add: '/admin/batch/add',
  edit: '/admin/batch/:itemId',
  getEditLink: (itemId: string) => `/admin/batch/${itemId}`,
};
