import { useMutation } from '@apollo/client';
import CardGridLoader from '@components/Loaders/CardGridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  QueryOrder,
  UpdateItemBatchInput,
} from 'apps/things/app/types/thingsGlobalTypes';
import { RootState } from 'store/epics/userPrefEpics';
import clientSimulator from 'utils/clientSimulator';
import EditItemBatch from '../components/EditItemBatch';
import { itemBatchRoutes } from '../constants';
import { updateItemBatchMutation } from '../mutations';
import { useGetAllItemBatches, useGetSpecificItemBatchQuery } from '../queries';
import { GetSpecificItemBatch_getSpecificItemBatch } from '../types/GetSpecificItemBatch';
import {
  UpdateItemBatch,
  UpdateItemBatchVariables,
} from '../types/UpdateItemBatch';
import { getDefaultSequence } from '../../utils';

const EditItemBatchContainer = () => {
  const notify = useNotifier();
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const items = useSelector((store: RootState) => store.items.items);

  const [lastSequence] = useState(
    getDefaultSequence(items.data?.node?.batchNumber)
  );

  const [itemBatch, setItemBatch] = useState(
    {} as GetSpecificItemBatch_getSpecificItemBatch
  );

  const { data, loading } = useGetSpecificItemBatchQuery({
    id: itemId,
  });

  const { refetch } = useGetAllItemBatches({
    first: 100,
    queryorder: QueryOrder.DESC,
  });

  useEffect(() => {
    setItemBatch(
      data?.getSpecificItemBatch ||
      ({} as GetSpecificItemBatch_getSpecificItemBatch)
    );
  }, [data]);

  const [updateItemBatch] = useMutation<
    UpdateItemBatch,
    UpdateItemBatchVariables
  >(updateItemBatchMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: 'success',
        text: 'Item batch updated successfully',
      });
      refetch();
      history(itemBatchRoutes.list);
    },
  });

  const handleUpdate = (updateItemBatchInput: UpdateItemBatchInput) => {
    updateItemBatch({
      variables: { updateItemBatchInput },
    });
  };

  if (loading || !itemBatch._id) return <CardGridLoader />;
  return (
    <EditItemBatch
      itemBatch={itemBatch}
      handleUpdate={handleUpdate}
      isLoading={loading}
      lastSequence={lastSequence}
    />
  );
};

export default EditItemBatchContainer;
