import { useMutation } from "@apollo/client";
import useNotifier from "hooks/useNotifier";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import { provisionBatchMutation } from "../mutations";
import {
  ProvisionBatch,
  ProvisionBatchVariables,
} from "../types/ProvisionBatch";

const useProvisionBatch = (onCompleted: (data: any) => void) => {
  const notify = useNotifier();
  const [create, options] = useMutation<
    ProvisionBatch,
    ProvisionBatchVariables
  >(provisionBatchMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      onCompleted && onCompleted(data);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    create,
    options,
  };
};

export default useProvisionBatch;
