import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import Format2DPCell from '@components/ReactTable/Format2DPCell';
import React from 'react';
import { Row } from 'react-table';
import SkuTableActions from '../components/TableActions';
import { GetAllAssetAccounts_getAllAssetAccounts_page_edges } from '../types/GetAllAssetAccounts';
import TitleCaseCell from '@components/ReactTable/TitleCaseCell';
import StatusCell from '@components/ReactTable/StatusCell';
const thresholdWidth = 600;
let header:any = []

if (window.innerWidth <= thresholdWidth) {
  header = [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: 'Customer',
      accessor: 'node.credit.owner.name',
      Cell: TitleCaseCell
    },
    {
      Header: 'Account Number',
      accessor: 'node.asset.sellerItemID',
      Cell: ({ value, column }: { value: any, column: any }) => <StatusCell value={value} header={column.Header} />,
    },
    {
      Header: 'ItemOemItemID',
      accessor: 'node.asset.oemItemID',
    },
    { Header: 'ID', accessor: 'node._id' },
    {
      Header: 'payHistory',
      accessor: 'node.payHistory',
      hidden: true,
      Cell: DescriptionCell,
    },
    {
      Header: 'codeHistory',
      accessor: 'node.asset.codeGenerator.codeHistory',
      hidden: true,
      Cell: DescriptionCell,
    },
    {
      Header: 'Item Id',
      accessor: 'node.asset._id',
    },
    {
      Header: 'accountStage',
      accessor: 'node.accountStage',
    },
    {
      Header: 'c2uRatio',
      accessor: 'node.c2uRatio',
    },
    {
      Header: 'Last Credit Amount',
      accessor: 'node.creditAmount',
    },
    {
      Header: 'Balance',
      accessor: 'node.credit.balance',
      Cell: Format2DPCell
    },
    {
      Header: 'Currency',
      accessor: 'node.credit.currency',
    },
    {
      Header: 'manager',
      accessor: 'node.manager.orgContactPerson._id',
    },
    {
      Header: 'Account Status',
      accessor: 'node.credit.accountStatus',
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
  ]
} else  {
  header = [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },

    {
      Header: 'payHistory',
      accessor: 'node.payHistory',
      hidden: true,
      Cell: DescriptionCell,
    },
    {
      Header: 'codeHistory',
      accessor: 'node.asset.codeGenerator.codeHistory',
      hidden: true,
      Cell: DescriptionCell,
    },
    {
      Header: 'AccountNumber',
      accessor: 'node.asset.sellerItemID',
      Cell: ({ value, column, row }: { value: any, column: any, row: any }) => {
        const accountStatus = row.original.node.credit.accountStatus;
        return <StatusCell value={value} header={column.Header} accountStatus={accountStatus} />;
      },
    },

    {
      Header: 'Customer',
      accessor: 'node.credit.owner.name',
      Cell: TitleCaseCell
    },
    {
      Header: 'Phone Number',
      accessor: 'node.credit.owner.contact.phone',
    },
    {
      Header: 'Unit',
      accessor: 'node.credit.owner.address.unit',
    },
    {
      Header: 'Street',
      accessor: 'node.credit.owner.address.street',
      Cell: DescriptionCell
    },

    {
      Header: 'City',
      accessor: 'node.credit.owner.address.city',
    },
    {
      Header: 'SRPC',
      accessor: 'node.credit.owner.address.srpc',
    },
    {
      Header: 'Country',
      accessor: 'node.credit.owner.address.country',
    },
    {
      Header: 'PostCode',
      accessor: 'node.credit.owner.address.postcode',
    },
    {
      Header: 'Latitude',
      accessor: 'node.credit.owner.address.addressLocation.addressLatitude',
    },
    {
      Header: 'Longitude',
      accessor: 'node.credit.owner.address.addressLocation.addressLongitude',
    },
    {
      Header: 'Last Credit Amount',
      accessor: 'node.creditAmount',
      Cell: Format2DPCell
    },
    {
      Header: 'Balance',
      accessor: 'node.credit.balance',
      Cell: Format2DPCell
    },
    {
      Header: 'Currency',
      accessor: 'node.credit.currency',
    },

    {
      Header: 'ItemOemItemID',
      accessor: 'node.asset.oemItemID',
    },
    {
      Header: 'Item Id',
      accessor: 'node.asset._id',
    },
    {
      Header: 'accountStage',
      accessor: 'node.accountStage',
    },
    {
      Header: 'c2uRatio',
      accessor: 'node.c2uRatio',
    },

    {
      Header: 'manager',
      accessor: 'node.manager.orgContactPerson._id',
    },
    {
      Header: 'Account Status',
      accessor: 'node.credit.accountStatus',
      Cell: ({ value, column, row }: { value: any, column: any, row: any }) => {
        const accountStatus = row.original.node.credit.accountStatus;
        return <StatusCell value={value} header={column.Header} accountStatus={accountStatus} />;}
    },
    { Header: 'ID', accessor: 'node._id' },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
  ]
}
/**
 * type Activity {
action: String!
amount: Float
datetime: DateTime!
notes: String
}
 */

export const accountActivitiesData = [
  {
    Header: 'amount',
    accessor: 'amount',
  },
  {
    Header: 'action',
    accessor: 'action',
  },
  {
    Header: 'notes',
    accessor: 'notes',
    Cell: DescriptionCell,
  },
  {
    Header: 'datetime',
    accessor: 'datetime',
    Cell: DateCell,
  },
];

export const itemTableData = () => ({

  header: header
});


export function addLastCreditAmountColumn(items: GetAllAssetAccounts_getAllAssetAccounts_page_edges[]) {
  return items.map(item => {
    const activities = item.node?.credit?.activities || [];
    let creditAmount = 0;

    if (activities.length > 0) {
      const lastActivity = activities[activities.length - 1];
      const secondLastActivity = activities[activities.length - 2];
      
      if (lastActivity?.action === "CREDIT") {
        creditAmount = lastActivity.amount || 0;
      } else {
        creditAmount = secondLastActivity?.amount || 0;
      }
    }

    const newNode = { creditAmount, ...item.node };
    return { ...item, node: newNode };
  });
}