import gql from 'graphql-tag';
import { itemFragment } from './queries';

/**
 * deleteItem(
itemId: String!
): String!
 */
export const deleteItemMutation = gql`
  mutation DeleteItem($itemId: String!) {
    deleteItem(itemId: $itemId)
  }
`;

/**
 * updateItem(
updateItemInput: UpdateItemInput!
): Item!
 */
export const updateItemMutation = gql`
  ${itemFragment}
  mutation UpdateItem($updateItemInput: UpdateItemInput!) {
    updateItem(updateItemInput: $updateItemInput) {
      ...Item
    }
  }
`;

/**
 * createItem(
createItemInput: CreateItemInput!
): Item!
 */
export const createItemMutation = gql`
  ${itemFragment}
  mutation CreateItem($createItemInput: CreateItemInput!) {
    createItem(createItemInput: $createItemInput) {
      ...Item
    }
  }
`;

/**
 * createSingleItem(
createItemInput: CreateItemInput!
): Item!
 */
export const createSingleItemMutation = gql`
  ${itemFragment}
  mutation CreateSingleItem($createItemInput: CreateItemInput!) {
    createSingleItem(createItemInput: $createItemInput) {
      ...Item
    }
  }
`;

