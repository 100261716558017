/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionScope {
  CLIENT = "CLIENT",
  DEVELOPMENT = "DEVELOPMENT",
  GLOBAL = "GLOBAL",
  MANAGEMENT = "MANAGEMENT",
  SYSTEM = "SYSTEM",
}

export enum ActorTypes {
  ACCOUNT = "ACCOUNT",
  DEVICE = "DEVICE",
  EVENT = "EVENT",
  ORGANIZATION = "ORGANIZATION",
  PERSON = "PERSON",
  PLACE = "PLACE",
  SOFTWARE = "SOFTWARE",
}

export enum BatchState {
  Completed = "Completed",
  Scheduled = "Scheduled",
  Submitted = "Submitted",
}

export enum CodeSystemType {
  ACP1 = "ACP1",
  ACP2 = "ACP2",
}

export enum CodeTypes {
  DAYSCODE = "DAYSCODE",
  FREECODE = "FREECODE",
  JUMPCODE = "JUMPCODE",
  RESETCODE = "RESETCODE",
  SYNCCODE = "SYNCCODE",
}

export enum LifeCycle {
  ASSIGNED = "ASSIGNED",
  INITIATED = "INITIATED",
  INSTALLED = "INSTALLED",
  PRODUCED = "PRODUCED",
  RECYCLED = "RECYCLED",
  RETURNED = "RETURNED",
  SHIPPED = "SHIPPED",
}

export enum OpenTokenCodeEventType {
  ACCEPTED_TOKEN = "ACCEPTED_TOKEN",
  GENERATED_TOKEN = "GENERATED_TOKEN",
}

export enum OpenTokenCodeType {
  ADD_TIME = "ADD_TIME",
  ALREADY_USED = "ALREADY_USED",
  COUNTER_SYNC = "COUNTER_SYNC",
  DISABLE_PAYG = "DISABLE_PAYG",
  INVALID = "INVALID",
  SET_TIME = "SET_TIME",
}

export enum QueryOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ThingIDTypes {
  DOI = "DOI",
  MAC = "MAC",
  UUID = "UUID",
}

export interface AssignCodeGeneratorToItemInput {
  codeGeneratorId: string;
  itemId: string;
}

export interface AssignItemToItemFleetInput {
  itemFleetId: string;
  itemId: string;
}

export interface AttributeDto {
  prop: string;
  value: string;
  meta: string;
}

export interface BatchInitializeInput {
  itembatchId: string;
}

export interface CreateAvatarInput {
  shadow: string;
  itemAlive?: boolean | null;
  lastSync: any;
  lastPub: string;
  lastSub: string;
  att?: AttributeDto[] | null;
  sts?: AttributeDto[] | null;
  cmd?: AttributeDto[] | null;
  dta?: AttributeDto[] | null;
  dia?: AttributeDto[] | null;
}

export interface CreateItemBatchInput {
  batchNumber: string;
  itemSKUId: string;
  batchDate: any;
  description?: string | null;
}

export interface CreateItemFirmwareInput {
  version: string;
  description?: string | null;
  codeSystem: CodeSystemType;
}

export interface CreateItemFleetInput {
  description: string;
  freeCodeCount?: number | null;
  disableFreeCode?: boolean | null;
  resetCodeCount?: number | null;
  disableResetCodeCount?: boolean | null;
  dayCodeCountLimit?: number | null;
  disableDayCodeCountLimit?: boolean | null;
  totalCodeCountLimit?: number | null;
  disableTotalCodeCountLimit?: boolean | null;
  codeGenInterval?: number | null;
  disableCodeGenInterval?: boolean | null;
  maxCreditStackDays?: number | null;
  disableMaxCreditStackDays?: boolean | null;
  maxCreditStackCodeEvents?: number | null;
  disableMaxCreditStackCodeEvents?: boolean | null;
  daysToCheckDuration?: number | null;
  disableDaysToCheckDuration?: boolean | null;
  minimumDayCodesGenerated?: number | null;
  disableMinimumDayCodesGenerated?: boolean | null;
  fleetName: string;
  assignDate: any;
}

export interface CreateItemInput {
  itemStockId?: string | null;
  idString?: string | null;
  oemID?: string | null;
  oemItemID: string;
  sellerID?: string | null;
  sellerItemID?: string | null;
  itembatchId: string;
  itemFleetId?: string | null;
  itemfirmwareId: string;
  codegeneratorId?: string | null;
  totalProducts?: number | null;
  createWithoutQueue?: boolean | null;
  code?: string | null;
}

export interface CreateItemSKUInput {
  skuName: string;
  productBase: string;
  mainMediaURL?: string | null;
  oemDescription?: string | null;
  gatt?: GattDto | null;
}

export interface CreateSignalInput {
  jmp: any;
  publisher?: string | null;
  time: any;
}

export interface GattDto {
  name: string;
  attributes?: AttributeDto[] | null;
}

export interface GenerateCodeInput {
  itemId: string;
  description?: string | null;
}

export interface GenerateDaysCodeInput {
  itemId: string;
  codeDays: number;
  description?: string | null;
}

export interface GetAllClientItemsInItemFleet {
  itemFleetId: string;
  clientId: string;
}

export interface InitializeCodeGenInput {
  codeSystemId: string;
  itemId: string;
  freeCodeCount?: number | null;
  resetCodeCount?: number | null;
}

export interface InitializeOpenTokenCodeGenInput {
  oem_item_id: string;
  secret_key: string;
  starting_code: string;
  max_count: number;
  used_count: number;
}

export interface ReassignItemsFromItemFleetInputDto {
  itemId: string;
}

export interface ReverseCodeEventInput {
  itemId: string;
  codeEventID: string;
}

export interface UpdateAvatarByItemIDInput {
  itemId: string;
  shadow?: string | null;
  itemAlive?: boolean | null;
  lastSync?: any | null;
  lastPub?: string | null;
  lastSub?: string | null;
  att?: AttributeDto[] | null;
  sts?: AttributeDto[] | null;
  cmd?: AttributeDto[] | null;
  dta?: AttributeDto[] | null;
  dia?: AttributeDto[] | null;
}

export interface UpdateGattDto {
  attributes: AttributeDto;
}

export interface UpdateItemBatchInput {
  itemBatchId: string;
  actorTypes?: ActorTypes | null;
  actionScope?: ActionScope | null;
  batchNumber?: string | null;
  itemSKUId?: string | null;
  batchDate?: any | null;
  description?: string | null;
  actorName?: string | null;
  profile?: string | null;
  batchState?: BatchState | null;
}

export interface UpdateItemFirmwareInput {
  itemFirmwareId: string;
  actorTypes?: ActorTypes | null;
  actionScope?: ActionScope | null;
  actorName?: string | null;
  profile?: string | null;
  version?: string | null;
  description?: string | null;
  codeSystem?: CodeSystemType | null;
}

export interface UpdateItemFleetCodeGenInput {
  itemFleetId: string;
  freeCodeCount?: number | null;
  resetCodeCount?: number | null;
  disableResetCodeCount?: boolean | null;
  dayCodeCountLimit?: number | null;
  disableDayCodeCountLimit?: boolean | null;
  totalCodeCountLimit?: number | null;
  disableTotalCodeCountLimit?: boolean | null;
  codeGenInterval?: number | null;
  disableCodeGenInterval?: boolean | null;
  maxCreditStackDays?: number | null;
  disableMaxCreditStackDays?: boolean | null;
  maxCreditStackCodeEvents?: number | null;
  disableMaxCreditStackCodeEvents?: boolean | null;
  disableFreeCode?: boolean | null;
  daysToCheckDuration?: number | null;
  disableDaysToCheckDuration?: boolean | null;
  minimumDayCodesGenerated?: number | null;
  disableMinimumDayCodesGenerated?: boolean | null;
}

export interface UpdateItemFleetInput {
  description?: string | null;
  freeCodeCount?: number | null;
  disableFreeCode?: boolean | null;
  resetCodeCount?: number | null;
  disableResetCodeCount?: boolean | null;
  dayCodeCountLimit?: number | null;
  disableDayCodeCountLimit?: boolean | null;
  totalCodeCountLimit?: number | null;
  disableTotalCodeCountLimit?: boolean | null;
  codeGenInterval?: number | null;
  disableCodeGenInterval?: boolean | null;
  maxCreditStackDays?: number | null;
  disableMaxCreditStackDays?: boolean | null;
  maxCreditStackCodeEvents?: number | null;
  disableMaxCreditStackCodeEvents?: boolean | null;
  daysToCheckDuration?: number | null;
  disableDaysToCheckDuration?: boolean | null;
  minimumDayCodesGenerated?: number | null;
  disableMinimumDayCodesGenerated?: boolean | null;
  itemFleetId: string;
  fleetName?: string | null;
  actorTypes?: ActorTypes | null;
  actionScope?: ActionScope | null;
  actorName?: string | null;
  profile?: string | null;
  clientId?: string | null;
  assignDate?: any | null;
}

export interface UpdateItemInput {
  itemId: string;
  itemFleetId?: string | null;
  idType?: ThingIDTypes | null;
  actorTypes?: ActorTypes | null;
  actionScope?: ActionScope | null;
  actorName?: string | null;
  itemStockId?: string | null;
  profile?: string | null;
  idString?: string | null;
  description?: string | null;
  creationDate?: any | null;
  oemID?: string | null;
  oemItemID?: string | null;
  sellerID?: string | null;
  sellerItemID?: string | null;
  itembatchId?: string | null;
  itemfirmwareId?: string | null;
  lifeCycle?: LifeCycle | null;
  codegeneratorId?: string | null;
}

export interface UpdateItemSKUInput {
  itemSKUId: string;
  actorTypes?: ActorTypes | null;
  actionScope?: ActionScope | null;
  actorName?: string | null;
  profile?: string | null;
  productBase?: string | null;
  skuName?: string | null;
  mainMediaURL?: string | null;
  oemDescription?: string | null;
  gattName?: string | null;
  gatt?: UpdateGattDto | null;
}

export interface UpdateOpenTokenDecoderInput {
  oem_item_id: string;
  token: string;
  secret_key: string;
  starting_code: string;
  max_count: number;
  used_count: number;
}

export interface UpdateSignalInput {
  signalId: string;
  jmp?: any | null;
  publisher?: string | null;
  time?: any | null;
}

export interface UpdateTokenDataInput {
  oem_item_id: string;
  token_type: OpenTokenCodeType;
  token_value: number;
  max_count: number;
  starting_code: string;
  secret_key: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
