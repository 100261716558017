
import React, { useContext, useEffect, useState } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';
import { itemTableData } from '../constants/itemTableData';
import { PaymentsContext } from '../context/PaymentsProvider';
import CardWrapper from '@components/Card/CardWrapper';
import TablePreview from '@components/Loaders/TablePreview';
import { useGetCodeEventsByCodeGeneratorIds, useLazyGetCodeEventsByCodeGeneratorIds } from '../queries';
import { processItemsData } from './processPayment';
interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
}

const MessageGroupPage: React.FC<IRTable> = () => {
  const {
    items,
    getItems,
    loading,
    goTo,
    paging,
    setPageLimit,
    handleFilterWithDates,
    handleResetFilterWithDates,
    setSearchQuery,
    searchQuery,
    filterDates,
    totalCount,
    getTotalCreditAmount,
    getDownloadCreditActivities,
    csvData,
    setCsvData,
    allAssets,
    query,
    downloadLoading
  } = useContext(PaymentsContext);
  const [headers, setHeaders] = useState<Column[]>([]);
  const [updatedItem, setUpdatedItem] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [codeGenIds, setCodeGenIds] = useState<any>();
  useEffect(() => {
    getItems();
    getTotalCreditAmount();
    // eslint-disable-next-line
  }, []);

  const { data } = useGetCodeEventsByCodeGeneratorIds({
    ids: codeGenIds
  });

  const [getCodeEvents, { data: dataa, loading: downloadEventsLoading }] = useLazyGetCodeEventsByCodeGeneratorIds()


  // Step 2: Use the function inside `useEffect`
  useEffect(() => {
    let codeGeneratorIds = items
      .filter(item => item.node?.asset.codeGenerator?._id != null)
      .map(item => item.node?.asset.codeGenerator?._id);
    setCodeGenIds(codeGeneratorIds);
    if (data?.getCodeEventsByCodeGeneratorIds) {
      let from = "table"
      const { creditActivitiesData, totalAmount } = processItemsData(items, data, filterDates, from);

      setTotalAmount(totalAmount);
      paging.count = creditActivitiesData.length;

      if (items.length > 0) {
        setHeaders([...itemTableData]);
      }
      setUpdatedItem(creditActivitiesData);
    }
  }, [items, paging, filterDates, data]);

  const handleDownload = () => {
    getDownloadCreditActivities({
      variables: {
        query: query
      },
    });
  }

  useEffect(() => {
    // const assetAccounts = allAssetAccounts?.downloadAllAssetAccountActivities
    let codeGeneratorIds = allAssets?.filter((item: any) => item?.asset.codeGenerator?._id != null)
      .map((item: any) => item?.asset.codeGenerator?._id)
    if (codeGeneratorIds) {
      getCodeEvents({
        variables: {
          ids: codeGeneratorIds
        }
      })
    }
    if (
      dataa?.getCodeEventsByCodeGeneratorIds &&
      dataa.getCodeEventsByCodeGeneratorIds.length > 0) {
      let from = "download"
      const { creditActivitiesData, totalAmount } = processItemsData(allAssets, dataa, filterDates, from);
      let lastRow: any = []
      if (creditActivitiesData.length > 0) {
        lastRow = [
          {
            AccountStage: "",
            SellerItemId: ""
          },
          {
            AccountStage: "TOTAL AMOUNT",
            SellerItemId: String(totalAmount)
          }]
      }
      let CSVData: any = [...creditActivitiesData, ...lastRow]

      if (CSVData.length < 1) { CSVData = null }
      setCsvData(CSVData)
    }

    // eslint-disable-next-line 
  }, [allAssets, dataa])

  if (headers.length === 0) {
    return <TablePreview items={updatedItem} loading={loading} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              headers as Column[]
            }
            data={updatedItem}
            checkedIds={[]}
            isLoading={loading}
            paging={paging}
            goTo={goTo}
            handleFilterWithDates={handleFilterWithDates}
            handleResetFilterWithDates={handleResetFilterWithDates}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
            totalAmount={totalAmount}
            totalCreditAmount={totalCount}
            filterOptions={[filterDates]}
            handleProcessDownloadItems={handleDownload}
            exportData={csvData}
            downloadLoading={downloadLoading || downloadEventsLoading}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default MessageGroupPage;

