import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, {useEffect, useState } from 'react';
import { 
  useLazygetAllAssetAccountActivitiesQuery, 
  useLazyGetAllAssetAccountActivitiesCreditAmountQuery } from '../queries';
// import {useLazy}
import { 
  GetAllAssetAccountActivities,
  GetAllAssetAccountActivitiesVariables,
  GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges
 } from '../types/GetAllAssetAccountActivities';
 import { useLazydownloadAllAssetAccountActivitiesQuery } from '../queries';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';
import { originalFilterQuery } from '../constants';
import { IDateFilter } from '@components/ReactTable/FilterChips';

export interface IPaymentsContext {
  items: GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[];
  allAssetAccounts: any;
  setItems: (
    itemSKUs: GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[]
  ) => void;
  setTotalCreditAmount: (
    totalCreditAmount: number
  ) => void;
  totalCount: number
  loading: boolean;
  refetchItems:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllAssetAccountActivities>>)
    | undefined;
  getItems: () => void;
  allAssets: any;
  setCsvData: (csvData:any) => void;
  csvData: any;
  getDownloadCreditActivities: (variables:any) => void;

  getTotalCreditAmount: () => void;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  filterDates?: any;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
  handleFilterWithDates?: (fromDate: any, toDate:any) => any
  handleResetFilterWithDates?: () => any;
  query?: any;
  downloadLoading?: boolean
}

export const PaymentsContext = React.createContext<IPaymentsContext>(
  {} as IPaymentsContext
);

const PaymentsProvider: React.FC<IProps> = ({ children }) => {
  const [items, setItems] = React.useState<
  GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[]
  >([] as GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[]);
  const [totalCount, setTotalCreditAmount] = React.useState<number>(0);
  const [loading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterDates, setFilterDates] = useState<IDateFilter>({fromDate: null, toDate: null, type: "date"});
  const [query, setQuery] = useState(originalFilterQuery);
  const [allAssets, setAllAssets] = useState<any>()
  const [csvData, setCsvData] = useState(null);

  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();

  const originalQuery = originalFilterQuery
  const [
    getItems,
    { refetch: refetchItems, fetchMore, data, loading: dataLoading },
  ] = useLazygetAllAssetAccountActivitiesQuery({
    first: pageListLimit,
    query: originalQuery
  });
  const [getTotalCreditAmount, 
    {refetch: refetchCreditAmount, data: creditData},
  ] = useLazyGetAllAssetAccountActivitiesCreditAmountQuery({
    query: originalQuery
  })

  const [getDownloadCreditActivities,
    { data: allAssetAccounts, loading: downloadLoading},
  ] = useLazydownloadAllAssetAccountActivitiesQuery({
    query: originalQuery
  })

  useEffect(() => {
    if (data && !dataLoading) {
      setItems(data?.getAllAssetAccountActivities?.page?.edges || items);
      setTotalCreditAmount(creditData?.getAllAssetAccountActivitiesCreditAmount || 0)
      setPaging({
        ...data?.getAllAssetAccountActivities?.pageData,
        ...data?.getAllAssetAccountActivities?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data, creditData]);

  useEffect(() => {
    setAllAssets(allAssetAccounts?.downloadAllAssetAccountActivities)
  },[allAssetAccounts])
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }
  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllAssetAccountActivitiesVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllAssetAccountActivities } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccountActivities?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountActivities?.pageData,
        ..._data?.data?.getAllAssetAccountActivities?.page?.pageInfo,
      });
    }
  };
  function convertDateString(originalDateString:any, dateType: String) {
    const date = new Date(originalDateString);
    if (dateType === "fromDate") {
    date.setHours(0, 0, 0, 0);
    }
    else if (dateType === "toDate") {
      date.setHours(23, 59, 59, 999);
    }
    const isoString = date.toISOString();
    return isoString;
  }

  const handleFilterWithDates = (fromDate:Date, toDate: Date) => {
    setCsvData(null)
    setAllAssets([])
    let isoToDate = convertDateString(toDate, "toDate")
    let isoFromDate = convertDateString(fromDate, "fromDate")
    let query = `credit.activities.action:CREDIT AND credit.balance:>=0 AND credit.activities.datetime:>=${isoFromDate} AND credit.activities.datetime:<=${isoToDate}`

    refetchItems({
      first: pageListLimit,
      query: query
    })
    refetchCreditAmount({
      query: query
    })
    setFilterDates({fromDate: isoFromDate, toDate: isoToDate, type: "date"})
    setQuery(query)

  }

  const handleResetFilterWithDates = () => {
    setCsvData(null)
    setAllAssets([])
    setFilterDates({fromDate:null, toDate: null, type: "date"})
    setQuery(originalQuery)
    refetchItems({
      first: pageListLimit,
      query: originalQuery
    })
    refetchCreditAmount({
      query: originalFilterQuery
    })
  }

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (fetchMore) {
      const variables: GetAllAssetAccountActivitiesVariables = {
        first: pageListLimit,
      };
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllAssetAccountActivities } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      refetchCreditAmount({
        search: searchQuery,
        query: query
      })

      setItems(_data?.data?.getAllAssetAccountActivities?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountActivities?.pageData,
        ..._data?.data?.getAllAssetAccountActivities?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading: loading || dataLoading,
      refetchItems,
      getItems,
      getTotalCreditAmount,
      setTotalCreditAmount,
      goTo,
      paging,
      setSearchQuery,
      searchQuery, setPageLimit,
      handleFilterWithDates,
      handleResetFilterWithDates,
      filterDates,
      totalCount,
      getDownloadCreditActivities,
      allAssetAccounts,
      setCsvData,
      csvData, 
      allAssets, 
      query,
      downloadLoading
    }), // eslint-disable-next-line
    [items, paging, searchQuery, filterDates, totalCount, allAssetAccounts, csvData, allAssets, query, downloadLoading]
  );

  return (
    <PaymentsContext.Provider value={value}>
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsProvider;
