import { useLazyQuery } from "@apollo/client";
import { getAllCodeEventsForSpecificItemByDistributorQuery } from "apps/things/app/codeEvent/queries";
import {
  GetAllCodeEventsForSpecificItemByDistributor,
  GetAllCodeEventsForSpecificItemByDistributorVariables,
} from "apps/things/app/codeEvent/types/GetAllCodeEventsForSpecificItemByDistributor";
import { isEmptyArray } from "formik";
import { useEffect, useMemo, useState } from "react";
import clientSimulator from "utils/clientSimulator";
import * as _ from "lodash";
import { CodeGenerator_codeHistory } from "apps/things/app/codeGenerator/types/CodeGenerator";
import { useAuth } from "admin/auth/context/AuthProvider";
export const useGetAllCodeEventsByDistributorQuery = (
  variables?: GetAllCodeEventsForSpecificItemByDistributorVariables
) => {
  return useLazyQuery<
    GetAllCodeEventsForSpecificItemByDistributor,
    GetAllCodeEventsForSpecificItemByDistributorVariables
  >(getAllCodeEventsForSpecificItemByDistributorQuery, {
    client: clientSimulator,
    variables,
    onError: (error) => {},
  });
};

const useGetDistributorCodeEvents = ({
  itemId,
  autoload = true,
}: {
  itemId: string;
  distributorId?: string;
  autoload?: boolean;
}) => {
  const [items, setItems] = useState([] as CodeGenerator_codeHistory[]);
  const [refeching, setRefetching] = useState(false);
  const { isDistributor, isServicer, loggedInUserId } = useAuth();
  const [_getItems, { data, refetch, fetchMore, loading }] =
    useGetAllCodeEventsByDistributorQuery();

  const getVariables = () => {
    let variables: GetAllCodeEventsForSpecificItemByDistributorVariables = {
      itemId,
      first: 100,
    };

    if (isDistributor || isServicer) {
      variables.distributorId = loggedInUserId;
    }
    return variables;
  };

  const getItems = ({
    variables,
  }: {
    variables: GetAllCodeEventsForSpecificItemByDistributorVariables;
  }) => {
    if (loading || _.isEmpty(variables)) {
      return;
    }
    return _getItems({ variables: variables });
  };

  const fetchItems = async () => {
    setRefetching(true);

    const { data: refetchData } = await refetch(getVariables());
    //  refetchData.getAllCodeEventsForSpecificItemByDistributor
    setItems(
      _.map(
        _.get(
          refetchData,
          "getAllCodeEventsForSpecificItemByDistributor.page.edges",
          []
        ) || [],
        (it) => it.node as CodeGenerator_codeHistory
      )
    );
    setRefetching(false);
  };

  useEffect(() => {
    if (!autoload || !itemId) {
      return;
    }
    if (isEmptyArray(items)) {
      getItems({
        variables: getVariables(),
      });
    } // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (data) {
      setItems(
        _.map(
          _.get(
            data,
            "getAllCodeEventsForSpecificItemByDistributor.page.edges",
            []
          ) || [],
          (it) => it.node as CodeGenerator_codeHistory
        )
      );
    } // eslint-disable-next-line
  }, [data]);
  return useMemo(
    () => ({
      items,
      loading: loading || refeching,
      refetch,
      getItems,
      fetchItems,
      data,
      fetchMore,
      includeDistributorID: isServicer || isDistributor,
      loggedInUserId,
    }), // eslint-disable-next-line
    [items, data, loading, refeching]
  );
};

export default useGetDistributorCodeEvents;
