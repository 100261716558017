import React, { useContext, useEffect } from "react";

// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";
import { itemBatchTableData } from "../constants/itemBatchTableData";
import { ItemBatchContext } from "../context/BatchProvider";
import CardWrapper from "@components/Card/CardWrapper";
import { itemBatchRoutes } from "../constants";
interface IRTable {}

const ItemBatchPage: React.FC<IRTable> = () => {
  const {
    itemBatches,
    tableActions,
    goTo,
    paging,
    getItemBatches,
    loading,
    setSearchQuery,
    setPageLimit,
    searchQuery,
  } = useContext(ItemBatchContext);
  useEffect(() => {
    getItemBatches();
    // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={itemBatchTableData().header as Column[]}
            data={itemBatches}
            checkedIds={[]}
            tableActions={tableActions}
            tableTitle="Item Batch List"
            isLoading={loading}
            addItemPageLink={itemBatchRoutes.add}
            addItemBtnText="Add Item Batch"
            paging={paging}
            goTo={goTo}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemBatchPage;
