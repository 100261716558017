import React, { useEffect, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import CustomChip from '@components/CustomChip/CustomChip';

export interface IDateFilter {
  type: 'date';
  fromDate: string | null;
  toDate: string | null;
}

export interface IStatusFilter {
    type: 'status';
    status: string;
  }


type IFilter = IDateFilter | IStatusFilter

interface IFilterChipsProps {
  initialFilters: IFilter[];
  handleResetFilterWithDates?: any
}

const FilterChips: React.FC<IFilterChipsProps> = ({ initialFilters, handleResetFilterWithDates }) => {
  const [filters, setFilters] = useState<IFilter[]>(initialFilters);
  useEffect(() => {
    setFilters(initialFilters)
  },[initialFilters])
  const handleDelete = (filterToDelete: IFilter) => {
    handleResetFilterWithDates()
    setFilters((prevFilters) => prevFilters.filter(filter => filter.type !== filterToDelete.type));
  };
  const getLabel = (filter: IFilter): string => {
    switch (filter.type) {
      case 'date':
        return `From: ${moment(filter.fromDate).format('MMMM Do YYYY')} - To: ${moment(filter.toDate).format('MMMM Do YYYY')}`;
      case 'status':
        return `Status: ${filter.status}`;
      default:
        return 'Unknown Filter';
    }
  };

  return (
    <div>
      {filters.map((filter) => (
        <CustomChip
         key={filter.type}
          label={getLabel(filter)}
          onDelete={() => handleDelete(filter)}
          deleteIcon={<CancelIcon />}
          color='primary'
          background

        />
      ))}
    </div>
  );
};

export default FilterChips;
