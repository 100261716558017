import { NetworkStatus } from '@apollo/client';
import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  CreateItemBatchInput,
  QueryOrder,
} from 'apps/things/app/types/thingsGlobalTypes';
import { RootState } from 'store/epics/userPrefEpics';
import AddItemBatch from '../components/AddItemBatch';
import { itemBatchRoutes } from '../constants';
import { useGetAllItemBatches } from '../queries';

import { getDefaultSequence } from '../../utils';
import useCreateBatch from '../hooks/useCreateBatch';

const AddItemBatchContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const items = useSelector((store: RootState) => store.items.items);
  const { refetch, networkStatus } = useGetAllItemBatches({
    first: 100,
    queryorder: QueryOrder.DESC,
  });

  const [lastSequence] = useState(
    getDefaultSequence(items.data?.node?.batchNumber)
  );

  const onComplete = async (data: any) => {
    notify({
      status: 'success',
      text: 'Item batch created successfully.',
    });
    await refetch();
    history(itemBatchRoutes.list);
  };
  const { create, options } = useCreateBatch(onComplete);

  const handleSubmit = (createItemBatchInput: CreateItemBatchInput) => {
    create({
      variables: { createItemBatchInput },
    });
  };

  if (networkStatus === NetworkStatus.refetch) return <GridLoader />;
  return (
    <AddItemBatch
      handleSubmit={handleSubmit}
      isLoading={options.loading}
      lastSequence={lastSequence}
    />
  );
};

export default AddItemBatchContainer;
