import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { Row } from "react-table";
import ActionModal from "@components/Modal/ActionModal";
import { useMutation } from "@apollo/client";
import clientSimulator from "utils/clientSimulator";
import useNotifier from "hooks/useNotifier";
import {
  DeleteItemBatch,
  DeleteItemBatchVariables,
} from "../types/DeleteItemBatch";
import { deleteItemBatchMutation } from "../mutations";
import {
  useBatchFactoryExportQuery,
} from "../queries";
import { useNavigate } from "react-router-dom";
import { itemBatchRoutes } from "../constants";
import BatchTokenizeModal from "./BatchTokenize/BatchTokenizeModal";
import GridContainer from "@components/Grid/GridContainer";
import GridItem from "@components/Grid/GridItem";
import { Formik } from "formik";
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import { ItemBatchContext } from "../context/BatchProvider";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import { FleetContext } from "../../fleet/context/FleetProvider";
import { PAGE_LIST_LIMIT } from "@constants";
import OVSForm from "@components/Form/OVSForm";
import Button from "@components/CustomButtons/Button";
import AllItemsPage from "../../item/components/AllItemsPage";
import { productQIDOptions } from "apps/migration/app/utils";
import useProvisionBatch from "../hooks/useProvisionBatch";
import { ItemContext } from "../../item/context/ItemProvider";
import useBatchCode from "../hooks/useBatchCode";
import { useConvertJsonToCSV } from "hooks/useConvertJsonToCSV";
import * as _ from "lodash";
import { useLazyGetAllItemsQuery } from "../../item/queries";
import { QueryOrder } from "admin/types/globalTypes";
import TextInput from '@components/CustomInput/TextInput';
import { reassignBatchItemsFromItemFleetMutation, assignItemBatchToItemFleetMutation } from "../mutations";
import { reassignBatchItemsFromItemFleet, reassignBatchItemsFromItemFleetVariables } from '../types/reassignBatchItemsFromItemFleet'
import { assignItemBatchToItemFleet, assignItemBatchToItemFleetVariables } from '../types/assignItemBatchToItemFleet'
import { handleGQLErrors } from "utils/gqlErrors";
import { useGetTotalItemsInBatch } from "../hooks/useGetToalItemsInBatch";
import { numberWithCommas } from "utils";
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);

const ItemBatchActions: React.FC<IProps> = ({ row, ...rest }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();
  const { refetchItemBatches: refetch } = useContext(ItemBatchContext);
  const { refetchItems } = useContext(ItemContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [tokenizeModalOpen, setTokenizeModalOpen] = React.useState(false);
  const [fleetId, setFleetId] = useState("");
  const [itemsModalOpen, setItemsModalOpen] = useState(false);
  const [assignToFleetModalOpen, setAssignToFleetModalOpen] = useState(false);
  const [reAssignFleetModalOpen, setReAssignFleetModalOpen] = useState(false);
  const [assignToHolingFleetModalOpen, setAssignToHolingFleetModalOpen] = useState(false);
  const [provisionModalOpen, setProvisionModalOpen] = useState(false);
  const [totalItemsOpen, setTotalItemsOpen] = useState(false);
  const [itemFleet, setItemFleet] = useState('')
  
  const handleProvisionModalOpen = useCallback(
    (value: boolean) => setProvisionModalOpen(value),
    []
  );
  const { loading, total, getItems } = useGetTotalItemsInBatch({
    batchNumber: row.values["node.batchNumber"],
  });
  const getNotes = useCallback(() => { }, []);

  const [angazaQID] = useState(getNotes());
  const [provisionLoading, setProvisionLoading] = useState(false);

  const [product_qid, setProductQID] = useState(
    // @ts-ignore
    angazaQID || ""
  );
  const [disableQIDInput] = useState(
    // @ts-ignore
    !!angazaQID || false
  );
  const [batchCodeModalOpen, setBatchCodeModalOpen] = useState(false);

  const handleBatchCodeModalOpen = useCallback(
    (value: boolean) => setBatchCodeModalOpen(value),
    []
  );
  const { create: batchCode, options: batchCodeOpts } = useBatchCode((data) => {
    refetchItems && refetchItems();
    notify({ status: "success", text: "Successfully provisioned" });
  });


  const [codeDays, setCodeDays] = useState<number | string>(1);
  const handleCodeDaysChange = useCallback((value: string) => {
    setCodeDays(value);
  }, []);

  const handleBatchCodeSubmit = useCallback(() => {
    const days = parseInt(codeDays.toString(), 10);
    if (days < 0) {
      return notify({
        status: "error",
        text: "Code days must be more than zero.",
      });
    }
    batchCode({
      variables: {
        codeDays: days,
        batchId: row.values["node._id"],
      },
    });
  }, [codeDays, batchCode, row.values, notify]);

  const [deleteItem] = useMutation<DeleteItemBatch, DeleteItemBatchVariables>(
    deleteItemBatchMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: "success",
          text: "Item batch deleted successfully",
        });
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );


  const [downloadingBatch, setDownloadingBatch] = useState(false);

  const convertERMToLegacyCSV = (ermData: any) => {
    return ermData?.map((it: any) => {
      return {
        oem_product_ID: _.get(it, "oemItemID"),
        PAYG_product_ID: _.get(it, "sellerItemID"),
        PAYG_security_hash_top: _.get(it, "hashTop"),
        PAYG_security_hash_root: _.get(it, "hashRoot"),
        productBatchNumber: _.get(it, "batchNumber"),
        productModelName: _.get(it, "skuName"),
        Code: _.get(it, "codeDecString"),
      };
    });
  };
  const [getFactoryItems, { data, loading: loadingFactoryItems }] =
    useBatchFactoryExportQuery({
      batchId: row.values["node._id"],
    });

  const [
    getItemsByBatchNumber,
    { data: itemData, loading: itemLoading, refetch: refetchItemss },
  ] = useLazyGetAllItemsQuery({
    first: 2,
    queryorder: QueryOrder.DESC,
    search: row.values["node.batchNumber"]
  });

  useEffect(() => {
    // @ts-ignore
    setItemFleet(itemData?.getAllItems?.page?.edges[0]?.node?.itemFleet?.fleetName)

  }, [itemData])

  const [download, setDownloaded] = useState(false);
  const handleDownloaded = useCallback(
    (value: boolean) => setDownloaded(value),
    []
  );
  const { handleDownload } = useConvertJsonToCSV();
  useEffect(() => {
    if (download) {
      return;
    }
    if (loadingFactoryItems) {
      return notify({
        status: "success",
        text: "Downloading factory items...",
      });
    }
    if (data) {
      if (data?.batchFactoryExport?.length === 0) {
        notify({
          status: "error",
          text: "No data found",
        });
      }

      handleDownload({
        jsonData: convertERMToLegacyCSV(data?.batchFactoryExport),
        downloadFileName: `factory-batch-${row.values["node.batchNumber"]}.csv`,
      });
      handleDownloaded(true);
      return notify({ status: "success", text: "Downloaded factory items" });
    } // eslint-disable-next-line
  }, [data, download, loadingFactoryItems]);


  const [reassignBatchItems, { loading: reassingLoading }] = useMutation<reassignBatchItemsFromItemFleet, reassignBatchItemsFromItemFleetVariables>(
    reassignBatchItemsFromItemFleetMutation, {
    client: clientSimulator,
    onCompleted: () => {
      notify({
        status: "success",
        text: "Item batch Re-Assigned successfully",
      });
      setItemFleet("")
      setFleetId("")
      refetchItemss && refetchItemss();
      setReAssignFleetModalOpen(false);
    },
    onError: (err) => {
      handleGQLErrors(notify, err)
    }
  });
  const [assignBatchItems, { loading: assignLoading }] = useMutation<assignItemBatchToItemFleet, assignItemBatchToItemFleetVariables>(
    assignItemBatchToItemFleetMutation, {
    client: clientSimulator,
    onCompleted: () => {
      notify({
        status: "success",
        text: "Item batch Assigned successfully",
      });
      setFleetId("")
      refetchItemss && refetchItemss();
      setAssignToFleetModalOpen(false);
    },
    onError: (err) => {
      handleGQLErrors(notify, err)
    }
  });

  const handleAssignToFleet = async () => {
    if (!fleetId) {
      alert("Please select a fleet to proceed.");
      return;
    }
    assignBatchItems({ variables: { itemFleetId: fleetId, itemBatchId: row.values["node._id"] } })
  };
  const {
    getItems: getFleets,
    items: fleetItems,
    refetchItems: refetchFleets,
    loading: loadingFleet
  } = useContext(FleetContext);
  const searchFleet = async (search: string) => {
    if (!refetchFleets) {
      return Promise.resolve(
        fleetItems?.map((it) => ({
          name: it?.node?.fleetName,
          value: it?.node?._id,
        }))
      );
    }
    const refetchData = await refetchFleets({
      first: PAGE_LIST_LIMIT,
      search,
    });

    const items = refetchData?.data?.getAllItemFleets?.page?.edges?.map(
      (item) => {
        return {
          label: item?.node?.fleetName || "",
          value: item.node?._id || "",
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  const { create: provision, options: provisionOpts } = useProvisionBatch(
    (data) => {
      refetchItems && refetchItems();
      notify({ status: "success", text: "Successfully provisioned" });
    }
  );

  const searchProductQID = async (search: string) => {
    const res = productQIDOptions?.filter((it) => {
      return it?.label?.toLocaleLowerCase().includes(search);
    });
    return res;
  };

  const provisionProductBatch = () => {
    if (!product_qid) {
      return notify({ status: "error", text: "Product QID is required" });
    }

    setProvisionLoading(true);

    provision({
      variables: {
        qid: product_qid,
        batchId: row.values["node._id"],
      },
    });

    setProvisionLoading(false);
  };

  const handleReAssignBatchFleet = () => {
    if (!fleetId) {
      alert("Please select a fleet to proceed.");
      return;
    }
    reassignBatchItems({ variables: { itemFleetId: fleetId, itemBatchId: row.values["node._id"], isHolding: false } })
  }

  const handleAssignToHoldingFleet = () => {
    if (!fleetId) {
      alert("Please select a fleet to proceed.");
      return;
    }
    reassignBatchItems({ variables: { itemFleetId: fleetId, itemBatchId: row.values["node._id"], isHolding: true } })
  }
  const formFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "select-async",
            name: "fleetId",
            label: "Fleet",
            options: fleetItems?.map((it) => ({
              _id: it?.node?._id || "",
              name: it?.node?.fleetName || "",
            })),
            onChange: (e: any) => {
              setFleetId(e);
            },
            value: fleetId || "",
            searchPromise: searchFleet as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [fleetItems, fleetId]
  );
  return (
    <div className="actions-right" style={{ display: "flex" }}>
            <Tooltip
        title="Delete"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id="expander"
          aria-label="Delete"
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + " " + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        onClick={() => {
          history(itemBatchRoutes.getEditLink(row.values["node._id"]));
        }}
        title="Edit"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id="expander"
          aria-label="Edit"
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + " " + classes.edit}
          />
        </IconButton>
      </Tooltip>

      <HoverDropdown
        menus={[
          {
            name: "Initialize",
            action: () => {
              setTokenizeModalOpen(true);
            },
          },
          {
            name: "Assign to Fleet",
            action: () => {
              setAssignToFleetModalOpen(true);
              getFleets();
            },
          },
          {
            name: "Reassign Fleet",
            action: () => {
              setReAssignFleetModalOpen(true);
              getFleets();
              getItemsByBatchNumber();
            },
          },
          {
            name: "Assign to Holding",
            action: () => {
              setAssignToHolingFleetModalOpen(true);
              getFleets();
              getItemsByBatchNumber();
            },
          },

          {
            name: disableQIDInput ? "Angaza Re-provision" : "Angaza Provision",
            action: () => {
              setProvisionModalOpen(true);
            },
          },
          {
            name: "Batch Code",
            action: () => {
              setBatchCodeModalOpen(true);
            },
          },
          {
            name: "Download factory products",
            action: () => {
              getFactoryItems();
            },
          },
          {
            name: "Total Batch Items",
            action: () => {
              setTotalItemsOpen(true);
              getItems();
            }
          },
        ]}
      />

      {/* batch code  */}
      <DrawerSidebar
        isModalOpen={batchCodeModalOpen}
        toggleModal={handleBatchCodeModalOpen}
        title={product_qid ?? `Batch Code generation`}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h6>
              This will generate {codeDays} day code(s) for the products <br />{" "}
              in batchNumber {row.values["node.batchNumber"]}{" "}
            </h6>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridItem xs={12}>
              <TextField
                name="codeDays"
                value={codeDays}
                onChange={(e) => {
                  handleCodeDaysChange(e?.target?.value || "");
                }}
                type="number"
              />
            </GridItem>
            <Grid>
              <Button
                color="primary"
                onClick={() => {
                  handleBatchCodeSubmit();
                }}
                disabled={batchCodeOpts.loading}
                style={{ marginLeft: 12 }}
              >
                Save
              </Button>
            </Grid>
          </GridItem>
        </GridContainer>
      </DrawerSidebar>
      {/* initialization */}
      {tokenizeModalOpen ? (
        <BatchTokenizeModal
          isModalOpen={tokenizeModalOpen}
          setIsModalOpen={setTokenizeModalOpen}
          loading={false}
          handleOnClickOky={() => { }}
          progress={80}
          itemId={row.values["node._id"]}
        />
      ) : null}
      {/* assign to fleet */}
      {assignToFleetModalOpen ? (
        <DrawerSidebar
          isModalOpen={assignToFleetModalOpen}
          toggleModal={setAssignToFleetModalOpen}
          title={`Assign ${row.values["node.batchNumber"]} to fleet`}
          styles={{ maxWidth: "30vw", minWidth: "400px" }}
        >
          <GridContainer>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <OVSForm formFieldsData={formFieldsData} />
                  <GridItem sm={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleAssignToFleet();
                      }}
                      style={{ marginLeft: 12 }}
                      disabled={loadingFleet || assignLoading}
                    >
                      {assignLoading ? 'Loading...' : 'Save'}
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
          </GridContainer>
        </DrawerSidebar>
      ) : null}
      {reAssignFleetModalOpen ? (
        <DrawerSidebar
          isModalOpen={reAssignFleetModalOpen}
          toggleModal={setReAssignFleetModalOpen}
          title={`Re Assign ${row.values["node.batchNumber"]} Fleet`}
          styles={{ maxWidth: "30vw", minWidth: "400px" }}
        >
          <GridContainer>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <GridItem md={12}>
                    <TextInput
                      label='Item Fleet Name'
                      name={'item fleet'}
                      value={itemLoading ? "Loading..." : itemFleet ? itemFleet : "Loading..."}
                      multiline
                      disabled
                    />
                  </GridItem>
                  <OVSForm formFieldsData={formFieldsData} />
                  <GridItem sm={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleReAssignBatchFleet();
                      }}
                      style={{ marginLeft: 12 }}
                      disabled={itemLoading || reassingLoading}
                    >
                      {reassingLoading ? "Loading..." : "Save"}
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
          </GridContainer>
        </DrawerSidebar>
      ) : null}
      {assignToHolingFleetModalOpen ? (
        <DrawerSidebar
          isModalOpen={assignToHolingFleetModalOpen}
          toggleModal={setAssignToHolingFleetModalOpen}
          title={`Assign ${row.values["node.batchNumber"]} To Holding Fleet`}
          styles={{ maxWidth: "30vw", minWidth: "400px" }}
        >
          <GridContainer>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <GridItem md={12}>
                    <TextInput
                      label='Item Fleet Name'
                      name={'item fleet'}
                      value={itemLoading ? "Loading..." : itemFleet ? itemFleet : "Loading..."}
                      multiline
                      disabled
                    />
                  </GridItem>
                  <OVSForm formFieldsData={formFieldsData} />
                  <GridItem sm={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleAssignToHoldingFleet();
                      }}
                      style={{ marginLeft: 12 }}
                      disabled={itemLoading || reassingLoading}
                    >
                      {reassingLoading ? "Loading..." : "Reassign Fleet"}
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
          </GridContainer>
        </DrawerSidebar>
      ) : null}
      {/* delete modal */}
      {isModalOpen ? (
        <ActionModal
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          handleOnClickOkey={() =>
            deleteItem({ variables: { itemBatchId: row.values["node._id"] } })
          }
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText="Delete"
        >
          <div>Are you sure you want to delete this item Batch?</div>
        </ActionModal>
      ) : null}

      {downloadingBatch ? (
        <ActionModal
          isModalOpen={downloadingBatch}
          toggleModal={setDownloadingBatch}
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText="&nbsp;"
        >
          <div>Loading...</div>
        </ActionModal>
      ) : null}

      {/* assign item to batch */}
      {itemsModalOpen ? (
        <DrawerSidebar
          isModalOpen={itemsModalOpen}
          toggleModal={setItemsModalOpen}
          title={`Assigned Items ${row.values["node.batchNumber"]}`}
          styles={{ minWidth: "800px", maxWidth: "940px" }}
        >
          {itemsModalOpen ? (
            <AllItemsPage batchID={row.values["node._id"]} />
          ) : null}
        </DrawerSidebar>
      ) : null}
      {/* Show total items in Batch */}
      {totalItemsOpen ? (
        <ActionModal
          isModalOpen={totalItemsOpen}
          toggleModal={setTotalItemsOpen}
          handleOnClickOkey={() => { 
            setTotalItemsOpen(false) 
          } }
          handleOnClickCancel={() => { 
            setTotalItemsOpen(false) 
          }}
          okText="Ok"
        >
          <div>
            <b>Total: </b>
            <span>
              {loading ? "loading..." : `${numberWithCommas(total || 0)}`}
            </span>
          </div>
        </ActionModal>
      ) : null}
      {/* provision */}
      <DrawerSidebar
        isModalOpen={provisionModalOpen}
        toggleModal={handleProvisionModalOpen}
        title={product_qid ?? `Provision to ${product_qid}`}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h6>
              This will provision the products <br /> in batchNumber{" "}
              {row.values["pbatchNumber"]}{" "}
            </h6>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <OVSForm
              formFieldsData={[
                {
                  name: "",
                  fields: [
                    {
                      md: 12,
                      disabled: disableQIDInput,
                      type: "select-async",
                      name: "product_qid",
                      label: "Product QID",
                      options: productQIDOptions.map((it) => ({
                        _id: it.value,
                        name: it.label,
                      })),
                      onChange: (e: any) => {
                        setProductQID(e);
                      },
                      value: product_qid || "",
                      searchPromise: searchProductQID,
                    },
                  ],
                },
              ]}
            />
            <Grid>
              <Button
                color="primary"
                onClick={() => {
                  provisionProductBatch();
                }}
                disabled={provisionLoading || provisionOpts.loading}
                style={{ marginLeft: 12 }}
              >
                Save
              </Button>
            </Grid>
          </GridItem>
        </GridContainer>
      </DrawerSidebar>
    </div>
  );
};

export default ItemBatchActions;
